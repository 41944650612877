import { range } from '~/utils/RangeUtils';
import type { DateTime } from 'luxon';

export function hoursRange(): string[] {
  return range(0, 23).map((h) => h.toString().padStart(2, '0'));
}

export function minutesRange(): string[] {
  return range(0, 59, 5).map((m) => m.toString().padStart(2, '0'));
}

export function getWeekdayName(index: number): string {
  const weekdays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  return weekdays[index - 1] || '';
}

export function getWeekNumber(date: DateTime): string {
  const year = date.weekYear;
  const weekNumber = date.weekNumber;
  return `${year}-${weekNumber}`;
}
