export function range(start: number, end: number, step: number = 1): number[] {
  return [...generateRange(start, end, step)];
}

function* generateRange(start: number, end: number, step: number = 1): Generator<number> {
  for (let i = start; i <= end; ++i) {
    if (i % step === 0) {
      yield i;
    }
  }
}
